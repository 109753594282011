* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* font-family: "Poppins", sans-serif; */
}

body {
  /* font-family: "Poppins", sans-serif; */
  color: "rgba(0, 0, 0, 0.87)";
}



.MuiSvgIcon-root.MuiSelect-iconOutlined {
  color: rgb(225, 140, 168) !important;
  /* height: 10px; */
}


.css-1fdsijx-ValueContainer {
  padding: 10px 8px;
}
/* .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd {
  height: 13px !important;
} */
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
.css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 12px 14px !important;
}
/* .MuiFormLabel-root {
  line-height: 13px !important;
} */
/* {
  line-height: 13px !important;
} */
.MuiOutlinedInput-root-MuiSelect-root {
  line-height: 15px !important ;
}

/* CKEditor Content Styling */
.ck-content ul,
.ck-content ol {
  margin: 0 0 1em 2em;
  padding: 0;
}

.ck-content ul {
  list-style-type: disc;
}

.ck-content ol {
  list-style-type: decimal;
  margin: 0;
  padding: 0;
  list-style-position: inside; /* Ensures that the number is part of the content flow */
}
.ck-content ol li {
  margin: 0;
  padding: 0;
}

/* ********** Pagination Table *********** */

/* .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSvgIcon-root {
  color: rgb(225, 140, 168);
} */

.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium.css-1ygcj2i-MuiTableCell-root {
  color: white;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  /* font-family: "Poppins", sans-serif; */
}

.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.css-1ex1afd-MuiTableCell-root {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  /* font-family: "Poppins", sans-serif; */
}

.MuiButton-root.tableBtn {
  color: rgb(225, 140, 168) !important;
  border-color: rgb(225, 140, 168) !important;
  font-family: "Poppins", sans-serif !important;
}

/* ***************** Sidebar **************** */

/* .sidebarListItem .css-bshv44-MuiButtonBase-root-MuiListItem-root {
  padding: 0;
} */
.imageInput {
  padding: 10px !important;
  height: 25px !important;
}
.sidebarContainer .css-h4y409-MuiList-root {
  padding: 0px 6px 20px 6px !important;
  display: flex !important;
  flex-direction: column !important;

  /* gap: 10px; */
}
.MuiCircularProgress-colorPrimary {
  color: "white";
}

.sidebarListItem:hover {
  cursor: pointer;
  color: rgb(225, 140, 168) !important;

  /* .sidebarItemText .css-10hburv-MuiTypography-root {
    color: rgb(225, 140, 168)!important;
  }  */

  .sidebarIcon
    .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
    color: rgb(0, 156, 203) !important;
  }
}
/* .activeSidebarListItem {
  cursor: pointer;
  background-color: rgb(225, 140, 168);
  border-radius: 15px;
  font-weight: bold !important;
  .sidebarItemText .css-10hburv-MuiTypography-root {
    color: rgb(255, 254, 255)!important;
  }

  .sidebarIcon
    .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
    color: rgb(0, 156, 203) !important;
  }
} */


/* .sidebarIcon
  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  color: rgb(225, 140, 168) ;
} */

.sidebarprofieInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: "10px";
  width: 100%;
}
.MuiListItemIcon-root {
  min-width: 35px !important;
}
.sidebarprofieImg {
  width: 10rem;
  height: 10rem;
  border-radius: 50%; /* Ensure border-radius is applied correctly */
  object-fit: cover; /* Ensure the image fits well within the circular frame */
}

.radio .MuiTypography-root {
  line-height: 13px !important;
}

/* .sidebarAdminName .css-10hburv-MuiTypography-root {
  font-size: 20px !important;
  font-weight: 600 !important;
  font-family: "Poppins", sans-serif !important;
} */

/* ************* Auth CSS **************** */

.authContainer {
  /* border: 2px solid blue; */
  width: 85%;
  padding: 5%;
  box-shadow: 0 0 10px 0 #d9d9d9;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.authHeading {
  /* border: 2px solid rgb(0, 255, 204); */
  text-align: center;
  /* Add your typography styles here */
}

.authHeadingBox {
  /* border: 2px solid rgb(255, 238, 0); */
  display: "flex";
  flex-direction: "column";
  align-items: "center";
}

.authContent {
  /* border: 2px solid rgb(255, 42, 0); */
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.authFields {
  /* border: 2px solirgb(255, 0, 230)ue; */
  display: flex;
  flex-direction: column;

  gap: 0.1rem;
}

.checkboxField {
  margin-top: 2%;
  display: flex;
  justify-content: space-between;
}

.rememberMeField {
  display: flex;
  align-items: center;
  gap: 5px;
}

.rememberMe {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.25rem;
  color: #518eb9;
  border: 1px solid rgba(239, 239, 244, 1);
}

.forgotPasswordLabel {
  /* Add your typography styles here */
  color: #3996d2;
  cursor: pointer;
}

.otpInputContainer {
  border: "1px solid blue";
  display: "flex";
  align-items: "center";
  justify-content: "space-between";
  gap: "2%";
}

.otpInput {
  height: "70px";
  width: "12%";
  border-radius: "10px";
  font-size: "30px";
  text-align: "center";
  border: "1px solid rgba(225, 225, 225, 1)";
}

/* ***** Category ***** ProgramListing ***** TopicListing ***** DietaryListing ***** CuisineListing ***** DietType ***** RecipeListing ***** 
Workout Management *****  ForumListing ***** */

.category-container {
  /* border: 2px solid blue; */
  /* padding-top: 20px; */
  display: flex;
  flex-direction: column;
  gap: 20px;
}


.button-container {
  display: flex;
  justify-content: end;
}

.button-sub-container {
  display: flex;
  /* padding: 5px; */
  width: 100%;

  gap: 5%;
}


.row-button-container {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 10%;
}

.row-button {
  color: rgb(225, 140, 168);
  border-color: rgb(225, 140, 168);
}

/* ***** AddCategory ***** EditCategory ***** AddProgram ***** EditProgram ***** AddDietary ***** EditDietary ***** AddCuision ***** EditCuision 
***** AddDietType ***** EditDietType ***** Workout(All) *****  ViewProgram ***** ViewExerciseDetails ***** */

.add-edit-container {
  /* border: 2px solid blue; */
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.add-edit-content-section {
  /* border: 2px solid red; */
  padding: 20px 10%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.add-edit-content {
  /* border: 2px solid green; */
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.add-edit-subcontent {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.add-edit-input-container {
  /* border: 2px solid purple; */
  display: flex;
  align-items: center;
  gap: 7%;
  /* width: 50%; */
}

.add-edit-input-container.desc-box {
  /* border: 2px solid purple; */
  display: flex;
  align-items: start;
  gap: 2%;
}

.add-edit-input-label {
  /* border: 1px solid pink; */
  width: 60%;
}


.add-edit-input-field {
  /* border: 1px solid orange; */
  width: 100%;
}

.add-edit-button-section {
  /* border: 1px solid skyblue; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 5%;
}

.viewProfileImg {
  width: 100px;
  height: 100px;
}

/* ***** PrivacyPolicy ***** EditPrivacyPolicy ***** AboutUs ***** EditAboutUs ***** T&C ***** EditT&C ***** FAQs ***** AddFAQ ***** EditFAQ ***** BannerManagement ***** 
AddBanner ***** EditBanner ***** */

.StaticScreenContainer {
  /* border: 2px solid blue; */
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.StaticScreenTitle
  .MuiTypography-root.MuiTypography-body1.css-ahj2mt-MuiTypography-root {
  font-weight: 600 !important;
  font-size: 24px !important;
  color: #080515 !important;
  font-family: "Poppins", sans-serif !important;
  @media (maxwidth: 767px) {
    fontsize: 18px !important;
  }
}

.StaticScreenContent .css-ahj2mt-MuiTypography-root {
  font-weight: 400 !important;
  font-size: 16px !important;
  color: #080515 !important;
  font-family: "Poppins", sans-serif !important;
  @media (maxwidth: 767px) {
    fontsize: 8px !important;
  }
}

.StaticEditorContainer {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
}

.StaticBtnContainer {
  /* border: 2px solid red; */
  /* padding: 0 25%; */
  display: flex;
  /* gap: 2%; */
  justify-content: center;
}

.ck-editor__editable_inline {
  min-height: 300px; /* Adjust this value as needed */
}

.StaticScreenbtn {
  /* border: 2px solid red; */
  padding-left: 80%;
}

.StaticScreenContent {
  /* border: 2px solid skyblue; */
  display: Flex;
  flex-direction: column;
  gap: 20px;
}

/* ******* Edit Account */

.profile-img {
  width: 187px;
  height: 187px;
  object-fit: cover;
  border-radius: 50%;
  cursor: pointer;
}

.profile-img-upload-label {
  display: inline-block;
  position: relative;
}

.profile-img-upload-label input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.heading-container-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
/* Add this CSS to your main stylesheet or as inline styles */
.ckeditor-container {
  width: 100%; /* Adjust width as needed */

  /* Fixed height of the editor */

  border-radius: 4px; /* Rounded corners */
  padding: 10px; /* Padding inside the editor */
  /* Ensure padding and border are included in total width and height */

  overflow: hidden; /* Prevent horizontal scroll */
  word-wrap: break-word; /* Break long words */

  
}



.StaticEditor {
  width: 100%; /* Ensures the editor takes full width of container */
  box-sizing: border-box; /* Prevents padding from affecting width */
}

.ck-editor__editable_inline {
  white-space: pre-wrap; /* Wraps text */
  word-break: break-word; /* Breaks long words */
  overflow-wrap: break-word; /* Ensures long words break */
}
/* globalStyles.css */
.modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 600px;
  max-height: 600px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.cropper-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.modal-button {
  position: absolute;
  bottom: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.modal-button-crop {
  right: 20px;
  background-color: #007bff;
  color: #fff;
}

.modal-button-cancel {
  left: 20px;
  background-color: #6c757d;
  color: #fff;
}

.ckeditor-container{
padding: 0 !important;
}

input:-webkit-autofill {
  background-color: white; /* Set to desired background color */
  color: black; /* Set text color */
 
  height: 13px; /* Ensure it fits the height */
}

input:-webkit-autofill:focus {
  box-shadow: none /* Match focus effect */
}
